.verticalNav > a {
  display: inline-block;
  width: 100%;
  /* height: 100%; */
  text-align: center;
  padding-top: 20px;
  color: white;
  font-size: 20px;
}
.verticalNav {
  height: 100%;
  position: relative;
  background: #131313;
  height: 100vh !important;
  padding: 0px;
  margin: 0px;
  font-family: Clash Grotesk;
}
.verticalNav p {
  margin: 0px !important;
  padding: 0px !important;
}

.active-tab {
  color: #a2d254 !important;
  border-left: 4px solid #a2d254;
  transition: all 0.2s ease-in-out;
}

.show1 {
  display: block !important;
  transition: all 0.3s ease;
}

.dropdown-menu {
  min-width: 100px !important;
  position: relative !important;
  top: -42px !important;
  left: -18px !important;
  background-color: rgb(26 27 31) !important;
}

.show {
  outline: none !important;
  border: none !important;
}
.t {
  width: 250px !important;
  /* background-color: #131313; */
  border-radius: 12px;
  color: white;
}
.t > li:focus {
  color: black;
  border-bottom: 2px solid #a2d254 !important;
  /* background-color: red; */
  outline: none;
  border: none;
  position: static;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(11 11 11 / var(--tw-text-opacity));
  background: linear-gradient(45deg, #8fd41f 0%, #ffe569 40%) !important;
  background-size: 200% auto !important;
  transition: background-position 0.5s ease !important;
}

.react-tabs__tab--selected{
  background-color: none !important;
}

.t li {
  color: white;
  background-color: #131313;
  border: none;
}

.userCard div {
  /* height: 100px; */
  background-color: #131313;
  border-radius: 11px;
  font-family: Clash Grotesk;
  /* padding: 5px; */
}
.icons {
  /* display: inline-block; */
  font-size: 50px;
  padding-left: 5px;
  background: linear-gradient(
      45deg,
      rgb(143, 212, 31) 0%,
      rgb(255, 229, 105) 40%
    )
    text;
  -webkit-text-fill-color: transparent;
}
.userCard p{
  margin-bottom: 0px !important;
  padding-left: 0px;
}

@media (max-width: 600px) {
  .verticalNav {
    width: 175px !important;
    z-index: 555;
    display: none;
    position: absolute !important;
    top: 110px;
  }
  .con {
    width: 100% !important;
  }
  .mob-nav {
    display: block !important;
  }
  .con1 {
    width: 100% !important;
  }
  .hide {
    display: block !important;
    transition: display 0.3s ease;
  }
  .filtertable table {
    width: 600px !important;
    overflow-x: auto;
  }
}
