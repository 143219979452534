a {
  text-decoration: none;
}
a:hover {
  color: chartreuse !important;
}
.explore-button {
  width: 25%;
  margin-top: 30px;
}
.btn-gradient {
  border-radius: 60px;
  background: linear-gradient(90deg, #a2d254, #ffd300);
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.purchase-card {
  width: 41%;
}
.gradient-text {
  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.input-box {
  color: #e5e8eb;
  background-color: #26262a;
  background-clip: padding-box;
  border: 2px solid #252527;

  border-radius: 1.25rem;
}
.form-control:focus {
  color: #dddedf;
  background-color: #1d1d21;
  border-color: #3c3c3c;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(14 14 15 / 25%);
}
.f1 input {
  background: linear-gradient(
    20deg,
    rgb(143, 212, 31) 0%,
    rgb(255, 229, 105) 40%
  ) text !important;
  -webkit-text-fill-color: transparent !important;
  font-weight: 800 !important;
  border: 2px solid rgb(246, 227, 99) !important;
}

@media (max-width: 600px) {
  .explore-button {
    width: 100%;
    padding: 15px 38px;
    margin-top: 15px;
  }
  .purchase-card {
    width: 100%;
  }
}
