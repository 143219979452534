@font-face {
  font-family: "Clash Grotesk"; /*Can be any text*/
  src: local("ClashGrotesk-Regular"),
    url("./fonts/ClashGrotesk-Regular.ttf") format("truetype");
}
@import "https://api.fontshare.com/v2/css?f[]=satoshi@900,700,500,300,400&f[]=clash-grotesk@400,600,300,500&display=swap";

@keyframes react-loading-skeleton {
  to {
    transform: translate(100%);
  }
}
:root {
  /* --Gradient: linear-gradient(90deg, #add254 35.73%, #FFD300 40.73%); */
}
#tabp > button {
  border-radius: 18px !important;
  width: 80px;
  margin-left: 5px;
  height: 32px;
}

.re-top {
  border-radius: 9px !important;
  width: 150px !important;
}
.re-top:hover {
  color: #000 !important;
}
.text-gradient {
  text-align: center;
  background: linear-gradient(
      45deg,
      rgb(143, 212, 31) 0%,
      rgb(255, 229, 105) 40%
    )
    text;
  -webkit-text-fill-color: transparent;
}
.p-lg {
  text-align: center;
  color: white;
}
.form input {
  width: 100%;
  background: transparent;
  border: 2px solid rgb(49 49 49);
  border-radius: 100px;
  height: 40px;
  outline: none;
  color: white;
  padding-left: 10px;
  display: inline-block;
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition-duration: 0.2s;
  margin: 10px 0px;
}
.form1 input {
  width: 80%;
  background: transparent;
  border: none;
  outline: none !important;
  color: white;
  padding-left: 10px;
  display: inline-block;
  transition-duration: 0.2s;
  margin: 10px 0px;
}
.PhoneInput {
  padding-left: 8px;
}
.form-card {
  border: 2px solid rgb(29, 29, 31);
  border-radius: 12px;
  background-color: #171717;
  box-shadow: rgb(241 236 236 / 43%) 0px 14px 28px,
    rgb(251 249 249 / 6%) 0px 10px 10px;
}
.form-btn:hover {
  background-position: right center;
  color: #000 !important;
}
.form-btn {
  width: 100%;
  border-radius: 100px;
  text-align: center;
  background: linear-gradient(45deg, #8fd41f 0%, #ffe569 40%);
  background-size: 200% auto;
  transition: background-position 0.5s ease;
  font-size: 20px;
  color: #000 !important;
  height: 40px;
  padding-top: 5px;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 40px;
}

.slider {
  width: 100%;
  margin: 5px auto;
  -webkit-appearance: none;
  height: 8px;
  border-radius: 4px;
  margin-bottom: 15px;
  background-color: #ffe569;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  background-color: #b9da3b;
  overflow: visible;
  cursor: pointer;
  transition: all 1s ease;
}

.row {
  margin: 0;
  padding: 0;
}
body {
  background-color: black;
  /* font-family: "Clash Grotesk"; */
}

#w1 a:hover {
  color: white !important;
}
#bnb:hover {
  color: black !important;
}
.connect-btn-contact {
  width: 100%;
  height: 100%;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: linear-gradient(90deg, #a2d254 0%, #ffd300 100%);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  display: inline-flex;
}
.footer-logo {
  display: inline-block;
}
.explore-button {
  width: 25%;
  margin-top: 30px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;

  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  ) !important;
  color: black;
  font-weight: 600;
}
.nav-tabs .nav-link {
  background: #1d1d21;
  background: var(--Primary-Dark-Cards, #1d1d21);
  color: #fff;
  font-weight: 600;
}
.nav-link {
  background-color: #1d1d21 !important;
  color: #ffffff;
  border-radius: 3px !important;
}
.nav-link:focus,
.nav-link:hover {
  color: #e4e5e6;
}
#leader th,
td {
  text-align: center;
}

.td {
  font-family: Clash Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: end;
}

/* .custom-input::placeholder {
  color: #7d7b7b!important; 
  display: none!important;
} */

@media (max-width: 1760px) {
  .input input {
    font-size: 10px;

    /* line-height: 3; */
  }
  .input span {
    background-color: #171717;
    right: 3px !important;
  }
}

@media (max-width: 1540px) {
  .farm:nth-of-type(2) {
    width: 24%;
  }
  .farm:nth-of-type(4) {
    width: 14%;
    /* display: none; */
  }
}

@media (max-width: 1310px) {
  .tb {
    width: 60% !important;
    font-size: 20px !important;
  }
}
@media (max-width: 1292px) {
  #reward > div {
    height: 250px !important;
  }
  #rewardTable {
    margin-top: 20px !important;
  }
  #rewardTable .b {
    width: 230px !important;
    /* border: 1px solid; */
  }
  #rewardTable .t {
    background-color: transparent;
  }
}

@media (max-width: 600px) {
  .explore-button {
    width: 100%;
    margin-top: 30px;
  }
  #bannerDiv div {
    margin-top: 5px;
  }
  .rs {
    margin-left: 0px;
  }
}
#open a:hover {
  color: black !important;
}
#crypto {
  color: #fff;

  /* Heading 1 */
  font-family: Clash Grotesk;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#crypto span {
  background: linear-gradient(90deg, #a2d254 13.39%, #ffd300 98.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  /* Heading 1 */
  font-family: Clash Grotesk;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#link {
  color: var(--Gray-200, #c6c4c4) !important;
  text-align: center;

  /* Paragraph XX-Large */
  font-family: Satoshi;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#open {
  border-radius: 60px;
  background: var(
    --Primary-Linear-Gradient,
    linear-gradient(90deg, #a2d254 0%, #ffd300 100%)
  );
  line-height: 2;
  margin-top: 15px !important;
  padding: 6px 14px;
}
#open button {
  background-color: transparent;
  border: none;
  outline: none;
  color: #000;
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* padding-left: 5px; */
}
#left-b {
  /* border-radius: 30px; */
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background: linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%);
  height: 86px;
  flex-shrink: 0;
  margin-left: -25px;
}
.left-u {
  width: 140px;
  height: 88px;
  flex-shrink: 0;
  /* border-radius: 30px; */
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border: 4px solid #2f2f2f;
  margin-left: -25px;
  margin-bottom: -40px;
}
#infoNum {
  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-align: center; */

  /* Heading 2 */
  font-family: Clash Grotesk;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#infoNum2 {
  color: var(--Yeti-White, #fff);

  /* Paragraph X-Large */
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#feature p {
  color: var(--Gray-300, #9b9b9b);
  text-align: center;

  /* Paragraph Large */
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#feature #div1 {
  text-align: center;

  /* Heading 2 */
  font-family: Clash Grotesk;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(90deg, #a2d254 35.73%, #ffd300 40.73%);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #a2d254;
}
#feature #div2 {
  color: var(--Yeti-White, #fff);
  text-align: center;
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 3px;
}

#filterTable1 {
}
#comText {
  text-align: center;

  /* Heading 2 */
  font-family: Clash Grotesk;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#i3 {
  border-radius: 60px;
  background: var(--Yeti-White, #fff);
  padding: 16px 14px;
  height: 60px;
  margin-top: 10px;
}
#i3 span {
  color: #1276ea;
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
#vault {
  margin: auto;
}
#close-btn {
  color: white !important;
}
#vault a {
  text-align: center;
  width: 30%;
  color: white;
  margin: 5px;
  border: 1px solid #313131;
  border-radius: 6px;
}
#openapp:hover {
  color: black !important;
}

@media (max-width: 1440px) {
  #i3 {
    line-height: 1;
  }
  #i3 span {
    font-size: 15px !important;
  }
  #harvest {
    font-size: 40px !important;
  }
}
@media (min-width: 450px) and (max-width: 992px) {
  #wallet1 .row > .col-lg-4 {
    position: relative !important;
  }
  #wallet2 {
    position: relative !important;
    right: 0 !important;
    margin-top: 10px !important;
  }
  #w {
    position: relative !important;
  }
  #wallet {
    height: auto !important;
    margin-bottom: 100px;
  }
  #logo {
    margin: 0 !important;
  }
  #logo img {
    width: 70% !important;
  }
  #logo #i3 {
    width: 60% !important;
    margin: auto;
  }
  #i3 span {
    font-size: 30px !important;
  }
  #logo div {
    z-index: 99999;
    margin-bottom: 30px;
  }
  #s {
    position: absolute !important;
    top: 68px !important;
  }
  #row > .col-lg-4 {
    text-align: center !important;
  }
  #row > .col-lg-4 p {
    padding: 0 !important;
  }
  #h {
    margin-top: 180px;
    height: 280px;
    margin-bottom: 140px;
  }
  .com1 {
    position: absolute !important;
  }
  #harvest {
    margin-left: 11px !important;
  }
  #h > .col-lg-8 > .row {
    height: 300px;
  }
}
.container-fluid {
  margin: 0 !important;
  padding: 0 !important;
}
.navc {
  margin: auto !important;
}
@media (max-width: 450px) {
  #wallet div {
    border-radius: 20px !important;
  }
  .container-fluid {
    padding: 5px !important;
  }
  .navc a {
    width: 50%;
  }
  .row {
    padding: 0;
    margin: 0;
  }
  .container-fluid > svg {
    display: none;
  }
  .fimg {
    padding-left: 10px !important;
  }
  .fdis {
    padding-left: 10px !important;
  }
  .fcon {
    padding-left: 10px !important;
  }
  #harvest {
    /* margin: 0 !important; */
  }
  #header nav {
    padding: 0 !important;
  }
  #header nav div {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }
  #header nav div div {
    padding: 1px !important;
    width: 154px !important;
    /* font-size: 10px; */
  }
  #header nav div div span {
    padding-left: 5px;
    font-size: 19px;
  }
  #header nav div a {
    margin: 0 !important;
  }
  #logo {
    margin: 0 !important;
  }
  div.navbar-brand {
    width: 40%;
  }
  #crypto {
    font-size: 40px !important;
  }
  .left-u {
    display: none;
  }
  #open {
    margin-bottom: 20px !important;
  }
  #row > .col-lg-4 {
    text-align: center !important;
  }
  #row > .col-lg-4 p {
    padding: 0 !important;
  }
  #wallet {
    height: auto !important;
  }
  #wallet1 .row > .col-lg-4 {
    position: relative !important;
  }
  #wallet2 {
    position: relative !important;
    right: 0 !important;
    margin-top: 10px !important;
  }
  #w {
    position: relative !important;
  }
  .com {
    display: none !important;
  }
  #logo div {
    margin-bottom: 20px !important;
  }
  #i3 {
    /* width: 300px !important; */
    margin: auto;
  }
  #rewardTable .t,
  .a {
    font-size: 12px !important;
  }
  #rewardTable {
    height: 200px !important;
  }
  #i3 span {
    font-size: 25px !important;
  }
  .modal-content .col-lg-11 {
    margin: 0px !important;
  }
  .apy {
    width: 50% !important;
    height: auto !important;
    border: 1px solid;
    border-radius: 2px !important;
  }
  .apy1 {
    width: 99% !important;
  }
  .deposit div h6 {
    font-size: 20px !important;
  }
  .claim span {
    font-size: 20px !important;
  }
  #reward > h6 {
    padding-left: 0px !important;
  }
}

.farm a {
  color: white;
  width: 33%;
  display: inline-block;
  text-align: center;
  margin: auto;
  font-family: Satoshi;
}

#filterTable a:hover {
  color: white !important;
}
#filterTable table {
  border-radius: 1px;
}
#filterTable thead {
  border-bottom: 1px solid var(--Gray-700, #313131);
  background: var(--Gray-700, #313131);
}
#filterTable tbody {
  height: 85px;
  border-bottom: 1px solid var(--Gray-700, #313131);
}
#filterTable th {
  height: 45px;
}
#filterTable th,
td {
  padding: 5px;
  text-align: center;
}
#filterTable table p {
  color: var(--Gray-500, #595959);
  font-family: Satoshi;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.pool {
  padding-left: 34px;
}
.tab-header {
  background-color: #595959;
  border-radius: 8px !important;
  margin: 10px;
}
.active {
  border-radius: 6px !important;
  background: #ffd300 !important;
  color: black !important;
}
.tab-header button {
  width: 50%;
  border: none;
  outline: none;

  background-color: #595959;
  color: white;
  border-radius: 8px;
}
#tabp {
  /* Paragraph Large */
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;

  /* background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
  /* 1111 */
  /* background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  */
}
#taba {
  color: var(--Gray-100, #e9e9e9);
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 6px;
  background: var(--Gray-700, #313131);
  line-height: 1;
  text-align: center;
  padding: 5px;
}

#td p {
  margin: 10px 10px;
}
.tj {
  text-align: left;
  color: var(--Yeti-White, #fff);

  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.te {
  text-align: end;
  color: var(--Yeti-White, #fff);
  text-align: right;

  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.apy {
  width: 25%;
  /* height: 100%; */
  border-right: 1px solid;
}
.apy:nth-of-type(4) {
  border: none;
}
.apy h6 {
  /* Heading 6 */
  font-family: Clash Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 5px;
  width: fit-content;
}

.apy p {
  color: var(--Gray-400, #7d7b7b);

  /* Paragraph Large */
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 5px;
}

.tvl,
.y {
  border: 1px solid #313131;
  border-radius: 6px;
  margin: 5px;
  /* margin: auto; */
  text-align: center;
}

.tvl a {
  width: 25%;
  display: inline-block;
  color: white;
  border-right: 1px solid #313131;
  /* margin: auto; */
}

.tvl a:nth-of-type(3) {
  width: 50% !important;
}

.y a {
  width: 20%;
  display: inline-block;
  color: white;
  border-right: 1px solid #313131;
}

.deposit div {
  border-radius: 21px;
  border: 1px solid #313131;
  background: var(--Gray-900, #171717);
  margin: 5px auto;
  padding: 10px;
}

.deposit div p {
  color: var(--Gray-500, #595959);

  /* Paragraph X-Large */
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.deposit div h6 {
  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  /* Heading 4 */
  font-family: Clash Grotesk;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#reward h6 {
  color: var(--Yeti-White, #fff);

  /* Paragraph XX-Large */
  font-family: Satoshi;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.click {
  border-radius: 8px;
  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
}
#reward > div {
  border-radius: 12px;
  border: 1px solid var(--Gray-700, #313131);
  height: 232px;
}

.claim span {
  /* Heading 4 */
  font-family: Clash Grotesk;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.claim a {
  position: absolute;
  right: 10px;
  display: inline-flex;
  padding: 6px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 60px;
  background: var(
    --Primary-Linear-Gradient,
    linear-gradient(90deg, #a2d254 0%, #ffd300 100%)
  );
  color: black;
}
.claim a:hover {
  color: black !important;
}
.claim p {
  color: var(--Gray-500, #595959);

  /* Paragraph Large */
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.input {
  padding: 0px;
  line-height: 2;
}
.input input {
  border-radius: 12px;
  border: 1px solid var(--Gray-700, #313131);
  width: 100%;
  outline: none;
  background-color: transparent;
  color: white;
  padding-left: 5px;
}
.input span {
  position: absolute;
  right: 5px;
  cursor: pointer;
}
.t {
  color: var(--Gray-500, #595959);

  /* Paragraph Large */
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.a {
  color: var(--Yeti-White, #fff);

  /* Paragraph Large */
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: end;
}

/* {
  border: 5px solid red;

} */
.dropdown1 {
  background-color: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
  max-height: auto;
  position: absolute;
  height: auto;
  width: 142px;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  border-radius: 10px !important;
  background-color: #1a1b1f !important;
  border: 1px solid #ccc0 !important;
  box-shadow: 0 1px #0000000f !important;
}
.dropdown1 a {
  display: block;
  margin: auto;
  text-align: center;
  width: 100%;
  color: white;
  font-family: inherit;
}
.dropdown a:hover {
  background-color: #3a3a3d !important;
  color: #fff;
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.deposit1 > h6,
.deposit1 > p {
  text-align: center;
}
@media (max-width: 680px) {
  .farm {
    width: 47%;
    margin: 5px !important;
  }
  .farm a {
    font-size: 10px;
    /* font-weight: bolder; */
  }
  #cf {
    position: relative !important;
    right: 0 !important;
    width: 105px;
  }
  #cf a {
    font-size: 15px;
  }
  /* #filterTable th,
  td {
    font-size: 10px;
  } */
  /* #filterTable table span {
    font-size: 10;
    height: 14px !important;
    width: 14px !important;
  }
  #filterTable table svg,
  #filterTable img {
    height: 10px;
    width: 10px;
  }
  .pool svg {
    height: 20px;
  } */
}

.with:hover {
  color: black !important;
}
.pagination1 {
  background-color: #fbfbfb6e;
  font-size: 22px;
  min-width: 900px;
}
.pagination1 li {
  color: red !important;
  font-size: 28px;
}

@media (max-width: 820px) {
  #filterTable table {
    width: 820px !important;
    overflow: auto;
  }
  .input input {
    font-size: 10px;
  }
  .modal-content .col-lg-11 {
    margin: 20px !important;
  }
}

@media (max-width: 1368px) {
  .farm {
    width: 47%;
    margin: 5px !important;
  }
  #cf {
    position: relative !important;
    right: 0 !important;
    width: 105px;
  }
  #tab1 {
  }
  .apy {
    width: 50% !important;
  }
  .apy1 {
    width: 100% !important;
    border: 1px solid !important;
  }
  .farm:nth-of-type(2) {
    width: 47%;
  }
  .farm:nth-of-type(4) {
    width: 47%;
    /* display: none; */
  }
}
@media (max-width: 992px) {
  #c {
    margin: 2px !important;
  }
  .y {
    position: relative !important;
  }
  .tvl {
    margin: 0px !important;
    /* padding: 5px; */
  }
  #brk {
    width: 100%;
  }
  #vault a {
    width: 47% !important;
  }
  .rs {
    margin-left: 0px !important;
  }
}
#priceD th {
  border: 1px solid white !important;
  padding: 5px;
}
#deposit a:hover {
  color: black !important;
}

.cCard {
  border: 2px solid rgb(29, 29, 31);
  border-radius: 12px;
  background-color: #171717;
  min-height: 450px;
}
.cCard label {
  color: white;
  font-size: 16px;
}
.cCard > div {
  flex-shrink: 0;
}
.calculatorForm {
  justify-content: space-between;
}
.calculatorForm input {
  width: 100%;
  background: transparent;
  border: 2px solid rgb(49 49 49);
  border-radius: 5px;
  outline: none;
  color: white;
}
.slct {
  height: 65px;

  border: none;
  margin-top: 10px;
}
.slct > select {
  background: transparent;
  border: 2px solid rgb(49 49 49);
  border-radius: 100px;
  color: white;
  width: 98%;
  margin: auto;
  height: 35px;
  outline: none;
}

.slct > select > option {
  background: transparent;
  color: black;
  outline: none;
  border: none;
}

.cCard button {
  height: 40px;
  width: 400px;
  margin: auto;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cCard label {
  margin-bottom: 5px;
}

.dash {
  /* border: 1px solid white; */
  /* justify-content: space-evenly; */
  padding-top: 20px;
}
.dash > div {
  /* border: 1px solid white; */
  align-self: flex-start;
  height: 260px;
  width: 290px;
  margin-bottom: 20px;
  border-radius: 30px;
  margin-right: 20px;
  /* border: 2px solid rgb(246, 227, 99) */
}
.dash > .col-lg-5:nth-child(1) {
  background: #febf4b;
}
.dash > .col-lg-5:nth-child(2) {
  background-color: #37373f;
  /* background-color: #1e5c4e; */
}
.dash > .col-lg-5:nth-child(3) {
  background-color: #ccdd47;
  margin-right: 20px;
}
.dash > .col-lg-5:nth-child(4) {
  background-color: #203e21;
}

.dash > .col-lg-5:nth-child(6) {
  background-color: #e2c964;
}

.dash > .col-lg-5:nth-child(5) {
  background-color: #222222;
}

.dash > .col-lg-5:nth-child(7) {
  background: linear-gradient(
    45deg,
    rgb(143, 212, 31) 0%,
    rgb(255, 229, 105) 40%
  );
}

.dash > .col-lg-5:nth-child(9) {
  background: linear-gradient(101.73deg, #c3a100 0%, rgb(57 92 0 / 88%) 96.33%);
}

.dash > .col-lg-5:nth-child(10) {
  background: linear-gradient(101.73deg, #9d8f4d 0%, rgb(83 87 77 / 88%) 96.33%);
}

.d1,
.d2,
.d3,
.d4,
.d6 {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
}
.d1 > span,
.d2 > span,
.d3 > span,
.d4 > span,
.d6 > span {
  width: 40px;
  height: 40px;
  margin: 20px 10px 10px 18px;
  border-radius: 100%;
  background-color: #37373f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.d1 > a {
  color: black;
  font-size: 16px;
  margin: 20px 10px 10px 18px;
  font-weight: 500;
  border: 1px solid black;
  border-radius: 30px;
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.d1 > a:hover {
  color: black;
}
.d2 > a,
.d6 > a {
  color: white;
  font-size: 16px;
  margin: 20px 10px 10px 18px;
  font-weight: 500;
  border: 1px solid white;
  border-radius: 30px;
  width: fit-content;
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.d2 > a:hover,
.d6 > a:hover {
  color: white !important;
}
.d3 > a {
  color: black;
  font-size: 16px;
  margin: 20px 10px 10px 18px;
  font-weight: 500;
  border: 1px solid black;
  border-radius: 30px;
  width: fit-content;
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .d3 > a:hover {
  color: black;
} */

.d4 > a {
  color: black;
  font-size: 16px;
  margin: 20px 10px 10px 18px;
  font-weight: 500;
  border: 1px solid black;
  border-radius: 30px;
  width: fit-content;
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.d5 > a:hover {
  color: white;
}

.guide {
  background: linear-gradient(
      45deg,
      rgb(143, 212, 31) 0%,
      rgb(255, 229, 105) 40%
    )
    text;
  -webkit-text-fill-color: transparent;
  font-size: 28px;
  font-weight: 600;
  margin-top: 15px;
}
.e {
  margin-top: 50px !important;
}
.wys,
.d,
.e {
  color: white;
  font-size: 50px;
  font-weight: bolder;
  margin-top: 10px;
}

.investment {
  color: black;
  height: 110px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 7px solid #afaab5;
  border-left-color: #37373f;
  border-right-color: yellow;
  border-bottom-color: yellow;
  border-top-color: #37373f;
  font-size: 30px;
  font-weight: 500;
}
.dash-ref {
  border-top: 1px solid grey;
  margin: 15px auto 2px;
  padding-top: 5px;
  font-size: 20px;
}
.insInvestment {
  background-color: #fff472;
  margin: 20px auto 2px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: 500;
  font-size: 21px;
}

canvas {
  width: 100% !important;
}
.d1 > a:hover {
  color: black !important;
}
.d2 > a:hover {
  color: white !important;
}
.d3 > a:hover {
  color: black !important;
}
.d4 > a:hover {
  color: black !important;
}
.d5 > a:hover {
  color: white !important;
}
.userImg {
  height: 60px;
  display: flex;
  position: absolute;
  bottom: 0;
  flex-direction: row;
  padding: 0px !important;
}
.userName {
  display: flex;
  flex-direction: column;
  width: calc(100% - 70px);
  /* border: 1px solid red; */
  padding: 0px !important;
  color: white;
  height: 100%;
  position: relative;
  top: 5px;
}

@media (max-width: 1500px) {
  .dash > .col-lg-5:nth-child(3) {
    margin-right: 6px;
    /* background-color: #aea9a6; */
  }
  .dash > div {
    margin-right: 12px !important;
  }
}

@media (max-width: 450px) {
  .webDiv {
    margin-left: 0px !important;
  }
  .dash-ref {
    padding: 10px !important;
  }
  .insInvestment {
    width: 200px;
  }
  .userImg {
    position: relative !important;
    margin-top: 10px;
  }
  .userImg img {
    padding-left: 0px !important;
  }
  .dash {
    justify-content: center;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: yellow;
  animation: l15 1s infinite linear;
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}
.loader::after {
  margin: 8px;
  animation-duration: 3s;
}
@keyframes l15 {
  100% {
    transform: rotate(1turn);
  }
}
.lDiv {
  height: 100vh;
  background-color: #000000d6;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  z-index: 999;
  flex-direction: column;
  /* filter: blur(10px); */
}
